import {EMPTY, Observable, of} from "rxjs";
import {tap} from "rxjs/operators";
import {ExamSession, ExerciseSession, ExerciseSessionQuestion, ItemAvailability} from "../../../model/cspa/personal";
import {Chapter, ExerciseSet} from "../../../model/cspa/struct";
import {AnswerDefinitionBase, Question} from "../../../model/cspa/questions";
import {LogsService} from "../../../../utils/services/logs.service";
import {CspaExamApi, CspaMobileNativeApi} from "../cspa.api";
import {CspaFullRestApi} from "./rest/cspa.service";


export class WebMobileKindEmulatedService implements CspaMobileNativeApi, CspaExamApi {
  private dontSendSessions = true;
  storedSessions: ExerciseSession[] = [];
  storedExerciseSets: ExerciseSet[] = null;
  storedChapters: Map<string, Chapter[]> = new Map();
  chaptersUpdateTime: Map<string, number> = new Map();
  storedAvailabilities: Map<string, ItemAvailability[]> = new Map();
  availabilitiesUpdateTime : Map<string, number> = new Map();
  storedQuestions: Map<string, Question<any, any>[]> = new Map();
  questionsUpdateTime: Map<string, number> = new Map();
  private currentSession: ExerciseSession = null;
  private topAvailabilitiesSyncTime: number;
  private topAvailabilities: ItemAvailability[];

  sendStoredSessions(): Observable<any> {
    if (this.dontSendSessions) return EMPTY;
    this.log(`web is storing sessions`)
    if (this.storedSessions.length == 0) {
      return EMPTY;
    }
    return this.regularCspaRest.submitSessions(this.storedSessions).pipe(
      tap( _ => this.storedSessions = [])
    )
  }

  listExerciseSets(syncFrequencyMs: number): Observable<ExerciseSet[]> {
    if (this.storedExerciseSets) return of(this.storedExerciseSets);
    return this.regularCspaRest.listExerciseSets().pipe(
      tap( exerciseSets => this.storedExerciseSets = exerciseSets)
    );
  }

  syncChapters(exerciseSet: string, chaptersSyncFrequency: number): Observable<Chapter[]> {
    const updateTime = this.chaptersUpdateTime.get(exerciseSet);
    if (updateTime && new Date().getTime() - updateTime < chaptersSyncFrequency) return EMPTY;
    this.log(`syncing chapters for ${exerciseSet}`);
    return this.regularCspaRest.getChapters(exerciseSet).pipe(
      tap( chapters => {
        this.storedChapters.set(exerciseSet, chapters);
        this.chaptersUpdateTime.set(exerciseSet, new Date().getTime());
      })
    );
  }

  syncAvailabilities(exerciseSet: string, availabilityFrequencyMs: number): Observable<any> {
    const updateTime = this.availabilitiesUpdateTime.get(exerciseSet);
    if (updateTime && new Date().getTime() - updateTime < availabilityFrequencyMs) return EMPTY;
    this.log(`syncing availabilities for ${exerciseSet}`);
    return this.regularCspaRest.customListAvailabilities(exerciseSet, 3).pipe(
      tap( availability => {
        this.storedAvailabilities.set(exerciseSet, availability);
        this.availabilitiesUpdateTime.set(exerciseSet, new Date().getTime());
      })
    );
  }

  syncTopAvailabilities(availabilitySyncFrequency: number): Observable<any> {
   const updateTime = this.topAvailabilitiesSyncTime;
   if (updateTime && new Date().getTime() - updateTime < availabilitySyncFrequency) return EMPTY;
   this.log("syncing top availabilities");
   return this.regularCspaRest.customListAvailabilities("", 2).pipe(
     tap(availabilities => {
       this.topAvailabilities = availabilities;
       this.topAvailabilitiesSyncTime = new Date().getTime();
     })
   )
  }

  syncQuestions(exerciseSet: string, syncFrequencyMs: number): Observable<any> {
    const updateTime = this.questionsUpdateTime.get(exerciseSet);
    if (updateTime && new Date().getTime() - updateTime < syncFrequencyMs) return EMPTY;
    this.log(`syncing questions for ${exerciseSet}`);
    return this.regularCspaRest.listQuestions([exerciseSet]).pipe(
      tap( questions => {
        this.log('got questions response');
        this.storedQuestions.set(exerciseSet, questions);
        this.questionsUpdateTime.set(exerciseSet, new Date().getTime());
      })
    );
  }

  getChapters(exerciseSet: string): Observable<Chapter[]> {
    return of(this.storedChapters.get(exerciseSet));
  }

  getAvailabilities(exerciseSet: string): Observable<ItemAvailability[]> {
    return of(this.storedAvailabilities.get(exerciseSet));
  }

  getTopAvailabilities(): Observable<ItemAvailability[]> {
    if (!this.topAvailabilities) {
      this.topAvailabilities = [];
    }
    return of(this.topAvailabilities);
  }

  getQuestions(exerciseSet: string): Observable<Question<any, any>[]> {
    return of(this.storedQuestions.get(exerciseSet));
  }

  storeCurrentSession(session: ExerciseSession): Observable<ExerciseSession> {
    this.currentSession = session;
    return of(session);
  }

  getCurrentSession(): Observable<ExerciseSession> {
    return of(this.currentSession);
  }

  pushSession(session: ExerciseSession): Observable<ExerciseSession> {
    this.storedSessions.push(session);
    return of(session);
  }

  storeAvailability(exerciseSet: string, availability: ItemAvailability[]): Observable<ItemAvailability[]> {
    this.logger.log("storing availabilities")
    this.storedAvailabilities.set(exerciseSet, availability);
    return of(availability);
  }

  storeTopAvailabilities(topAvailabilities: ItemAvailability[]): Observable<void> {
    this.logger.log("storing top availabilities")
    this.topAvailabilities = topAvailabilities;
    return of(null)
  }

  constructor(private regularCspaRest: CspaFullRestApi, private logger: LogsService) {
  }

  private log(text: string) {
    this.logger.log(`[NATIVE_BY_EWB] ${text}`);
  }

  close(): void {
    this.log('DO CLOSE');
  }

  finishExamSession(sessionUuid: string): Observable<ExamSession> {
    this.log(`finishing exam session (${sessionUuid})`);
    return this.regularCspaRest.finishExamSession(sessionUuid)
  }

  finishExamSessionPart(sessionUuid: string): Observable<ExamSession> {
    this.log(`finishing exam session (${sessionUuid}) part`);
    return this.regularCspaRest.finishExamSessionPart(sessionUuid)
  }

  getExamSession(sessionUuid: String): Observable<ExamSession> {
    this.log(`getting exam session (${sessionUuid})`);
    return this.regularCspaRest.getExamSession(sessionUuid)
  }

  isOffline(): Observable<boolean> {
    return of(true);
  }

  postExamSessionQuestionAnswer<A extends AnswerDefinitionBase>(sessionUuid: string, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>): Observable<ExamSession> {
    this.log(`posting question answer exam session (${sessionUuid})`);
    return this.regularCspaRest.postExamSessionQuestionAnswer(sessionUuid, questionNb, sessionQuestion)
  }

  startExamSession(path: string): Observable<ExamSession> {
    this.log(`starting exam session for ${path}`);
    return this.regularCspaRest.startExamSession(path)
  }

  startExamSessionPart(sessionUuid: string, partNumber: number): Observable<ExamSession> {
    this.log(`starting exam session (${sessionUuid}) part nb ${partNumber}`);
    return this.regularCspaRest.startExamSessionPart(sessionUuid, partNumber)
  }
}
