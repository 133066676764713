import { Component, OnInit, ViewChild } from '@angular/core';
import {Page, Pageable} from "../../utils/pageable";
import {ApiLearningUnitTeacher, ApiLessonInstance, ApiPersonTechnicalProfile, ApiPersonalProfile, IntroductionState} from "../../col/model/rest-model";
import {Observable, of, switchMap, tap, zip} from "rxjs";
import {LessonType} from "../../col/model/calendar";
import {AuthorizationServiceProvider} from "../../auth_profile/services/authorization-service.provider";
import {SpinnerService} from "../../utils/services/spinner.service";
import {StudentRestServiceImpl} from "../../col/services/student/student-rest-impl.service";
import { AuthorizationServiceImpl } from 'src/app/auth_profile/services/api/impl/authorization.service';
import { ModalComponent } from 'src/app/utils/modal/modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  lessons: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  studentId: number
  schoolId: number

  technicalProfile: ApiPersonTechnicalProfile
  readonly introductionState = IntroductionState

  @ViewChild('welcomeModal', {static: true})
  welcomeModal: ModalComponent;

  constructor(private authService: AuthorizationServiceProvider,
              private spinner: SpinnerService,
              private studentRest: StudentRestServiceImpl,
              private authorizationServiceImpl: AuthorizationServiceImpl) { }

  ngOnInit(): void {
    this.authService.getAuthDetailsService().pipe(
      switchMap(api => zip(api.getSelfSchoolId(), api.getSelfStudentId())),
      tap(([schoolId, studentId]) => {
        this.schoolId = schoolId
        this.studentId = studentId
        if (this.studentId) {
          this.loadTechnicalProfile();
        }
      }),
      switchMap(_ => this.getLessons())
    ).subscribe()
  }

  getLessons(): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    if (!this.studentId)
      return of(null);
    return this.spinner.trace<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(
      this.studentRest
        .getLessons(
          this.studentId,
          new Pageable(0, 1, [
            'lessonStudent.metricDetails.plannedStartDate,ASC',
          ]),
          LessonType.Upcoming,
          ''
        )
        .pipe(
          tap((lessons) => (this.lessons = lessons)),
        )
    );
  }

  loadTechnicalProfile(){
    this.studentRest.loadTechnicalProfile(this.studentId).subscribe(
      technicalProfile => {
        this.technicalProfile = technicalProfile
        if (this.technicalProfile.introductionState === IntroductionState.InvitedToColNew){
          this.welcomeModal.show()
        }
      }
    )
  }

  welcomeToNewCallan(){
    this.studentRest.updateIntroductionState(this.studentId, IntroductionState.WelcomedOnColNew).subscribe()
    this.welcomeModal.hide()
  }

  backToOldCallan(){
    this.authorizationServiceImpl.getAccessCode()
      .subscribe(res => {
        const redirectUrl = encodeURIComponent(btoa(`student/${this.studentId}/dashboard`))
        window.location.href = `${environment.oldCallanServerBase}/oauth?code=${res}&state=${redirectUrl}`
      })
  }

}
