import {Injectable} from '@angular/core';
import {Observable } from 'rxjs';
import {CspaExamApi, CspaMobileNativeApi, MobileNativeAudio} from "../cspa.api";
import {IosBridgeBase} from "../../../../mobile/api-services/bridge/ios-bridge-base";
import {LogsService} from "../../../../utils/services/logs.service";
import {ExamSession, ExerciseSession, ExerciseSessionQuestion, ItemAvailability} from "../../../model/cspa/personal";
import {Chapter, ExerciseSet} from "../../../model/cspa/struct";
import {AnswerDefinitionBase, Question} from "../../../model/cspa/questions";
import {HttpParams} from "@angular/common/http";
import {Constraints, HttpMethod, HttpRequest, Platform} from "../../../../constraints";

@Injectable({
  providedIn: 'root'
})
export class IosNewNativeApi
  implements CspaMobileNativeApi, MobileNativeAudio, CspaExamApi {
  private apiEndpoint = Constraints.androidInternalApiEndpoint;

  constructor(private bridgeBase: IosBridgeBase,private logger: LogsService) {
  }

  // #### audio implementation #####
  /*
  this is the same implementation of audio actions as it was in old cspa
   */

  /**
   * clear recording buffer
   */
  clear(): Observable<void> {
    this.bridgeBase.log("calling clear");
    return this.bridgeBase.callForResponse<void>('clear',{});
  }

  /**
   * start recording
   */
  record() {
    this.bridgeBase.log("calling record");
    return this.bridgeBase.callForResponse<void>('record',{}).subscribe();
  }

  /**
   * stop recording or playback
   */
  stop(): Observable<void> {
    this.bridgeBase.log("calling stop");
    return this.bridgeBase.callForResponse<void>('stop',{});
  }

  /**
   * start recording playback
   */
  play2() {
    this.bridgeBase.log("calling play");
    return this.bridgeBase.callForResponse<void>('play',{}).subscribe();
  }

  /**
   * clean up and release recording device
   */
  terminate() {
    this.bridgeBase.log("calling terminate");
    return this.bridgeBase.callForResponse<void>('terminate',{}).subscribe();
  }

  /**
   * initialize recording device, returns true if positive
   * @param callback
   */
  initAudio(callback: (state: boolean) => void) {
    this.bridgeBase.log(" calling init");
    return this.bridgeBase.callForResponse<boolean>('initAudio',{}).subscribe(
      it => callback(it)
    )
  }

  // #### data management implementation #####

  /**
   * read the availability JSON file for exerciseSet
   * @param exerciseSet
   */
  getAvailabilities(exerciseSet: string): Observable<ItemAvailability[]> {
    this.bridgeBase.log(`getting availabilities for ${exerciseSet}`);
    return this.bridgeBase.callForResponse<ItemAvailability[]>('readAvailabilities', {exerciseSet});
  }

  getTopAvailabilities(): Observable<ItemAvailability[]> {
    this.bridgeBase.log(`getting top availabilities`);
    return this.bridgeBase.callForResponse<ItemAvailability[]>('readTopAvailabilities', {});
  }

  /**
   * read chapters JSON file for exerciseSet
   * @param exerciseSet
   */
  getChapters(exerciseSet: string): Observable<Chapter[]> {
    this.bridgeBase.log(`getting chapters for ${exerciseSet}`);
    return this.bridgeBase.callForResponse<Chapter[]>('readChapters', {exerciseSet});
  }

  /**
   * read current session JSON file
   */
  getCurrentSession(): Observable<ExerciseSession> {
    this.bridgeBase.log(`getting current session`);
    return this.bridgeBase.callForResponse<ExerciseSession>('readCurrentSession', {});
  }

  /**
   * read questions file for exerciseSet
   * @param exerciseSet
   */
  getQuestions(exerciseSet: string): Observable<Question<any, any>[]> {
    this.bridgeBase.log(`getting questions for ${exerciseSet}`);
    return this.bridgeBase.callForResponse<Question<any, any>[]>('readQuestions', {exerciseSet});
  }

  /**
   * read exercise sets file (has to be synced by the native app !!!!)
   */
  listExerciseSets(syncFrequencyMs: number): Observable<ExerciseSet[]> {
    this.bridgeBase.log('getting exercise sets');
    return this.bridgeBase.callForResponse<ExerciseSet[]>('readExerciseSets',{syncFrequencyMs});
  }

  /**
   * push session to the FIFO sync queue
   * @param session
   */
  pushSession(session: ExerciseSession): Observable<ExerciseSession> {
    this.bridgeBase.log(`pushing session ${session.deviceUUID}`);
    return this.bridgeBase.callForResponse<ExerciseSession>('pushSession', {session: JSON.stringify(session)});
  }

  /**
   * send stored sync sessions queue to the API and clean up the queue if positive.
   * Sync in FIFO order
   */
  sendStoredSessions(): Observable<any> {
    this.bridgeBase.log(`sending sessions to the server`);
    return this.bridgeBase.callForResponse<any>('sendSessions', {});
  }

  /**
   * store availability in the file, dont change update time
   * @param exerciseSet
   * @param availability
   */
  storeAvailability(exerciseSet: string, availability: ItemAvailability[]): Observable<ItemAvailability[]> {
    this.bridgeBase.log(`storing availabilities for ${exerciseSet}`);
    return this.bridgeBase.callForResponse<ItemAvailability[]>('saveAvailability', {exerciseSet, availability: JSON.stringify(availability)});
  }

  storeTopAvailabilities(topAvailabilities: ItemAvailability[]): Observable<any> {
    this.bridgeBase.log(`storing top availabilities`);
    return this.bridgeBase.callForResponse<ItemAvailability[]>('saveTopAvailability', { availability: JSON.stringify(topAvailabilities)});
  }

  /**
   * store session in current session file
   * @param session
   */
  storeCurrentSession(session: ExerciseSession): Observable<ExerciseSession> {
    this.bridgeBase.log(`storing current session ${session.deviceUUID}`);
    return this.bridgeBase.callForResponse<ExerciseSession>('saveCurrentSession', {session: JSON.stringify(session)});
  }

  /**
   * do the file sync if required. Check if the existing version is older than @syncFrequencyMs
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncAvailabilities(exerciseSet: string, syncFrequencyMs: number): Observable<any> {
    this.bridgeBase.log(`syncing availabilities for ${exerciseSet}`);
    return this.bridgeBase.callForResponse<any>('syncAvailability', {exerciseSet, syncFrequencyMs});
  }

  syncTopAvailabilities(syncFrequencyMs: number): Observable<void> {
    this.bridgeBase.log(`syncing top availabilities`);
    return this.bridgeBase.callForResponse<any>('syncTopAvailability', {syncFrequencyMs});
  }

  /**
   * do the file sync if required. Check if the existing version is older than @syncFrequencyMs
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncChapters(exerciseSet: string, syncFrequencyMs: number): Observable<Chapter[]> {
    this.bridgeBase.log(`syncing chapters for ${exerciseSet}`);
    return this.bridgeBase.callForResponse<Chapter[]>('syncChapters', {exerciseSet, syncFrequencyMs})
  }

  /**
   * do the file sync if required. Check if the existing version is older than @syncFrequencyMs
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncQuestions(exerciseSet: string, syncFrequencyMs: number): Observable<any> {
    this.bridgeBase.log(`syncing questions for ${exerciseSet}`);
    return this.bridgeBase.callForResponse<any>('syncQuestions', {exerciseSet, syncFrequencyMs});
  }

  /**
   * do application close
   */
  close(): void {
    this.bridgeBase.log('calling for close the app.');
    this.bridgeBase.callForResponse<void>('close',{}).subscribe();
  }

  private buildPath(path: string) {
    return `${this.apiEndpoint}/api${path}`;
  }

  private appendParams(url: string, key: string, value: string, isFirst: boolean): string {
    if (isFirst) {
      url = url + "?"
    } else {
      url = url + "&"
    }
    if (!value) value = "";
    url = `${url}${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    return url;
  }

  private params(url: string, params: HttpParams) {
    if (!params || params.keys().length == 0) return url;
    let isFirst = true;
    const keys = params.keys();
    for (const key of keys) {
      const values = params.get(key);
      if (!values) continue;
      url = this.appendParams(url, key, values, isFirst);
      isFirst = false;
    }

    return url;
  }

  finishExamSession(sessionUuid: string): Observable<ExamSession> {
    this.bridgeBase.log(`finishing exam session (${sessionUuid})`);
    const request = new HttpRequest(
      Platform.Cspa, HttpMethod.POST,
      this.buildPath(`/exam-sessions/${sessionUuid}/finish`),
      null,
      null,
    )
    return this.bridgeBase.callForResponse<ExamSession>('exchange',{request});
  }

  finishExamSessionPart(sessionUuid: string): Observable<ExamSession> {
    this.bridgeBase.log(`finishing exam session (${sessionUuid}) part`);
    const request = new HttpRequest(
      Platform.Cspa, HttpMethod.POST,
      this.buildPath(`/exam-sessions/${sessionUuid}/finish-part`),
      null,
      null,
    )
    return this.bridgeBase.callForResponse<ExamSession>('exchange',{request});
  }

  getExamSession(sessionUuid: String): Observable<ExamSession> {
    this.bridgeBase.log(`getting exam session (${sessionUuid})`);
    const request = new HttpRequest(
      Platform.Cspa, HttpMethod.GET,
      this.buildPath(`/exam-sessions/${sessionUuid}`),
      null,
      null,
    )
    return this.bridgeBase.callForResponse<ExamSession>('exchange',{request});
  }

  isOffline(): Observable<boolean> {
    return this.bridgeBase.callForResponse<boolean>('isOffline',{});
  }

  postExamSessionQuestionAnswer<A extends AnswerDefinitionBase>(sessionUuid: string, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>): Observable<ExamSession> {
    this.bridgeBase.log(`posting question answer exam session (${sessionUuid})`);
    const request = new HttpRequest(
      Platform.Cspa, HttpMethod.POST,
      this.buildPath(`/exam-sessions/${sessionUuid}/questions/${questionNb}`),
      null,
      JSON.stringify(sessionQuestion),
    )
    return this.bridgeBase.callForResponse<ExamSession>('exchange',{request});
  }

  startExamSession(path: string): Observable<ExamSession> {
    this.bridgeBase.log(`starting exam session for ${path}`);
    const request = new HttpRequest(
      Platform.Cspa, HttpMethod.POST,
      this.buildPath(`/exam-sessions/create`),
      null,
      JSON.stringify(path),
    )
    return this.bridgeBase.callForResponse<ExamSession>('exchange',{request});
  }

  startExamSessionPart(sessionUuid: string, partNumber: number): Observable<ExamSession> {
    this.bridgeBase.log(`starting exam session (${sessionUuid}) part nb ${partNumber}`);
    let params = new HttpParams().append("localTime", (new Date()).getTime())
    const request = new HttpRequest(
      Platform.Cspa, HttpMethod.POST,
      this.params(this.buildPath(`/exam-sessions/${sessionUuid}/create/${partNumber}`), params),
      null,
      null,
    )
    return this.bridgeBase.callForResponse<ExamSession>('exchange',{request});
  }
}
